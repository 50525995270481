(function () {
  var ccmWrapperElement = document.createElement('script');
  var organisation = 'NPO';
  var site = 'zappelin.nl';
  var ccmDomain = ('https:' == document.location.protocol ? 'https://' : 'http://') + 'ccm.npo.nl';
  ccmWrapperElement.type = 'text/javascript';
  ccmWrapperElement.crossOrigin = 'anonymous';
  ccmWrapperElement.src = ccmDomain + '/sites/' + organisation + '/' + site + '/ccm-wrapper_' + window.ccmWrapperVersion + '.js';
  ccmWrapperElement.async = true;
  ccmWrapperElement.defer = ccmWrapperElement.async;
  var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(ccmWrapperElement, s);
  window.addEventListener('CCM_Wrapper_ready', function (e) {
    ccmWrapper.init();
  }, false);
})();
